import {Component, OnDestroy, OnInit,ViewChild} from '@angular/core';
import {PhoneService} from './service/phone.service'
import {PreloaderService} from './service/preloader.service'


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    popup:boolean;
    preloader:boolean = false;

    constructor(private phoneService:PhoneService,
                private preloaderService:PreloaderService) {

    }

    ngOnInit() {
        window.addEventListener('resize', this.appHeight);
        this.appHeight();
        this.phoneService.init();

        this.preloaderService.preloaderChanged.subscribe((value: any) => this.preloaderChanged(value))
    }

    ngOnDestroy() {

    }

    preloaderChanged(status:boolean){
        this.preloader = status;
    }

    preloaderIsActive(){
        if(this.preloader){
            return 'is-active';
        }else{
            return '';
        }
    }

    appHeight() {
        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
}


