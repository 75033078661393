import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PreloaderService} from "./preloader.service";
import {AuthenticationService} from "../auth/_services/authentication.service";

declare global {
    interface Window {
        myPhone: any;
        webkit?: any;
        androidFunction?: any;
    }
}

// interface Window {
//     webkit?: any;
//     androidFunction?: any;
// }

declare var window: Window;
declare var AndroidFunction;

@Injectable({
    providedIn: 'root'
})
export class PhoneService {
    token;
    //avatarChanged = '';

    public avatarChanged = new BehaviorSubject(null);

    constructor(private ngZone: NgZone,
                private preloaderService:PreloaderService,
                private authenticationService:AuthenticationService,) {


    }

    init() {
        window.myPhone = window.myPhone || {};
        window.myPhone.namespace = window.myPhone.namespace || {};
        window.myPhone.namespace.publicFunc = this.publicFunc.bind(this);

    }

    destroy(blockId: number) {
        window.myPhone.namespace.publicFunc = null;
    }

    publicFunc(json) {
        if (typeof json === 'string'){
            try {
                json = JSON.parse(json);
            }catch (e) {

            }
        }

        if(json.status){
            let command = json.command;
            if (command == 'changeLogo') {
                this.avatarChanged.next(Math.round(+new Date()/1000)+Math.random())
            }else if(command == 'preloaderShow'){
                //this.preloaderService.show();
            }else if(command == 'preloaderHide'){
                //this.preloaderService.hide();
            }
        }
    }

    //call phone

    setChangeAvatar() {
        let token = this.authenticationService.getToken();
        let json = '{"command":"changeLogo","token":"'+token+'"}';
        if(window.webkit){
            window.webkit.messageHandlers.phoneListener.postMessage(json);
        }

        //if(AndroidFunction) {
        AndroidFunction.getCommand(json);
        //}
    }

    setMakeCall(phone:string){
        let json = '{"command":"dialHelpPhone","phone":"'+phone+'"}';
        if(window.webkit){
            window.webkit.messageHandlers.phoneListener.postMessage(json);
        }

        AndroidFunction.getCommand(json);
    }
}
