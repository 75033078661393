import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {environment} from '../environments/environment';
import {JwtInterceptor} from './service/jwt.interceptor';

import {AuthModule} from './auth/auth.module';

import {AuthConfig} from './auth/_services/auth-config';
import {AUTH_CONFIG} from './config/api';
//import {PROJECT_SETTINGS} from './config/project';
import {SharedModule} from './shared/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
// import { MyComponentComponent } from './my-component/my-component.component';




// export function initApp() {

// }
// (window as any).token = '123';


export const authConfig: AuthConfig = {
    authUrl: AUTH_CONFIG.login,
    refreshTokenUrl: AUTH_CONFIG.refresh,
    loginRoute: '/login',
};

export function initApp() {
  return () => {
    return new Promise((resolve) => {
      /*const w: any = window;
      if (navigator.userAgent === 'QUIZ_APP') {

        window.document.body.innerHTML = (w.token !== undefined ? w.token : 'test') + window.document.body.innerHTML;
      } else {
        //console.log('1234');
        if (!environment.production && !w.token) {
          w.token = 'test';
        }
      }*/
      resolve();
    });
  };
}




@NgModule({
  declarations: [
    AppComponent,
    // MyComponentComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthModule.forRoot(authConfig, {currentUserRoles: '/api/session/roles'}),
      SharedModule,
      ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initApp
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },

      // {
      //     provide: PROJECT_SETTINGS,
      //     useFactory: initApp,
      //     multi: true,
      //     useExisting: {}
      // },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
