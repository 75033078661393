import {DOMAIN_API} from "./local_settings";
const API = DOMAIN_API + '/api';
const AUTH = API + '/users/session';
const USERS = API + '/users';
const CHAT = API + '/chat';
const PROFILE = API + '/profile';
const QUESTIONNAIRE = API + '/questionnaire';
const GUEST = API + '/guest';
const APP = API + '/app';

export const PROJECT_CONFIG = {
    helpPhone: '900300400'
};

export const AUTH_CONFIG = {
    login: AUTH + '/login',
    refresh: AUTH + '/refresh',
    current: AUTH + '/details',
    checkPhone: USERS + '/phone/check',
    sendPin: USERS + '/pin/request',
    alertPaperClip: APP + '/users/alertPaperClip'
};

export const CHAT_CONFIG = {
    getQuestionSteps: QUESTIONNAIRE + '/groups',
    getQuestions: QUESTIONNAIRE + '/questions',
    saveAnswer: QUESTIONNAIRE + '/answers/save',
    removeAnswer: QUESTIONNAIRE + '/answers/remove',
    getAnswer: QUESTIONNAIRE + '/answers/answer',

    // saveAnswer: API + '/jsons/find/file?name=newListItem',
    // removeAnswer: API + '/jsons/find/file?name=success',
};

export const PROFILE_CONFIG = {
    getPersonalInfo: PROFILE + '/personal',
    savePersonalInfo: PROFILE + '/personal/save',
    getProfileAvatar: PROFILE + '/personal/avatar',
    saveProfileAvatar: PROFILE + '/logo/uploadAndroid',
    saveGuestInfo: GUEST + '/guest/save',
    getGuestInfo: GUEST + '/guest/get',
};

