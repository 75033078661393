import {Component, EventEmitter, Input, OnInit, Output,Injectable} from '@angular/core';
import {PROJECT_CONFIG} from '../../../config/api';
import {PhoneService} from '../../../service/phone.service';
import {BrowsersDetectionService} from "../../../service/browsersDetection.service";

@Component({
    selector: 'app-button-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss']
})

@Injectable({
    providedIn: 'root'
})
export class ButtonHelpComponent implements OnInit {
    private projectSettings = PROJECT_CONFIG;

    @Output('onClick')
    emitClick: EventEmitter<any> = new EventEmitter();

    popup: boolean = false;

    constructor(private phoneService: PhoneService,
                private browsersDetection: BrowsersDetectionService) { }

    ngOnInit() {
    }


    showPopup() {
        this.popup = !this.popup;
        document.body.classList.toggle('modal', this.popup)
    }

    makeCall(){
        this.phoneService.setMakeCall(this.projectSettings.helpPhone);
        this.showPopup();
        //window.open('tel:'+this.projectSettings.helpPhone);
    }
    closePopup(e) {
        if (!e.target.classList.contains('button')) {
            this.popup = !this.popup;
            document.body.classList.toggle('modal', this.popup)
        }
    }

    close() {
        this.popup = !this.popup;
        document.body.classList.toggle('modal', this.popup)
    }

    getHelpPhone() {
        return this.projectSettings.helpPhone;
    }

    isAndroid() {
        return this.browsersDetection.isAndroid();
    }

}
