import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class DateResponseInterceptor implements HttpInterceptor {

  constructor() {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.body) {
      const req = request.clone({body: this.convertDatesToUTC(request.body)});
      console.log(req);
    }
    return next.handle(request).pipe(map(event => {
      // Pass through everything except for the final response.
      if (!(event instanceof HttpResponse)) {
        return event;
      }

      return this.processJsonResponse(event);
    }));
  }

  private processJsonResponse(res: HttpResponse<string>): HttpResponse<any> {
    const body = res.body as any;
    this.convertDates(body);
    return res.clone({body});
  }

  private convertDatesToUTC(object: object) {

    if (!object || !(object instanceof Object)) {
      return;
    }

    if (object instanceof Array) {
      for (const item of object) {
        this.convertDatesToUTC(item);
      }
    }

    for (const key of Object.keys(object)) {
      const value = object[key];

      if (value instanceof Array) {
        for (const item of value) {
          this.convertDatesToUTC(item);
        }
      }

      if (value instanceof Object) {
        if (value.constructor.name === 'Date') {
          object[key] = (value as Date).toISOString();
        } else {
          this.convertDatesToUTC(value);
        }
      }
      if (typeof value === 'string' && isNaN(+value) && value.match(/^\d{4}\-\d{2}\-\d{2}\T\d{2}\:\d{2}\:\d{2}\.\d{3}\+\d{4}$/)) {

        object[key] = new Date(value);
      }
    }
  }

  private convertDates(object: object) {
    if (!object || !(object instanceof Object)) {
      return;
    }

    if (object instanceof Array) {
      for (const item of object) {
        this.convertDates(item);
      }
    }

    for (const key of Object.keys(object)) {
      const value = object[key];

      if (value instanceof Array) {
        for (const item of value) {
          this.convertDates(item);
        }
      }

      if (value instanceof Object) {
        this.convertDates(value);
      }
      if (typeof value === 'string' && isNaN(+value) && value.match(/^\d{4}\-\d{2}\-\d{2}\T\d{2}\:\d{2}\:\d{2}\.\d{3}\+\d{4}$/)) {

        object[key] = new Date(value);
      }
    }
  }
}
