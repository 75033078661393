import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PreloaderService {
    public preloaderChanged = new BehaviorSubject(null);

    constructor(private ngZone: NgZone) {

    }

    show() {
        this.preloaderChanged.next(true);
    }

    hide() {
        let $this = this;
        setTimeout(function () {
            $this.preloaderChanged.next(false);
        },1000);
    }
}
