import {EventEmitter, Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {UserIdleService} from '../user-idle/user-idle.service';
import {Router} from '@angular/router';
import {AuthConfig} from './auth-config';
import {AUTH_CONFIG} from '../../config/api';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private config = AUTH_CONFIG;

    private storage = sessionStorage;
    roles: string[];
    rolesObservable = new EventEmitter<any>();
    userLoggedIn = new EventEmitter<any>();

    constructor(@Inject(AuthConfig) private authConfig: AuthConfig,
                private http: HttpClient,
                private userIdle: UserIdleService,
                private router: Router) {
    }

    isUserLogged() {
        return !!this.storage.getItem('currentUser');
    }

    sendPin(phone: string){
        return this.http.post<any>(this.config.sendPin, {phone});
    }

    login(phone: string, pin: string, kind: string) {
        return this.http.post<any>(this.authConfig.authUrl, {phone, pin, kind})
            .pipe(map((res: any) => {
                if (res.status) {
                    let result = res.result;

                    this.storage = sessionStorage;

                    this.storage.setItem('currentUser', JSON.stringify({token: result.token}));

                    this.roles = [];
                    //this.roles = [result.role];
                    this.rolesObservable.next(this.roles);

                }

                return res;
            }));
    }

    async logout() {
        this.storage.removeItem('currentUser');

        await this.checkSession();
        this.userLoggedIn.emit(false);

    }

    refreshToken() {
        if (!this.storage.getItem('currentUser')) {
            return;
        }
        if (this.authConfig.refreshTokenUrl) {
            return this.http.post<any>(this.authConfig.refreshTokenUrl, {}).subscribe(res => {
                this.storage.removeItem('currentUser');
                if (res && res.token) {
                    this.storage.setItem('currentUser', JSON.stringify({token: res.token}));
                }
            }, error => {
                this.storage.removeItem('currentUser');
            });
        }
    }

    async checkSession() {

        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {

            this.refreshToken();
        } else {
            this.router.navigate([this.authConfig.loginRoute],
                {
                    queryParams: this.authConfig.loginRoute !== location.pathname ?
                        {
                            returnUrl:
                            location.pathname
                        } : null
                });

        }
    }


    getToken(){
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                const currentUser = JSON.parse(this.storage.getItem('currentUser'));

                return currentUser.token;
            }catch (e) {

            }

        }

        return '';
    }

    alertPaperclip(data: {}){
        return this.http.post<any>(this.config.alertPaperClip, data).subscribe((res:any) => {
            console.log(res);
        });
    }
}
