import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
    @Input()
    color = 'blue';

    @Input()
    withIcon = false;

    @Input()
    mobileSmall: boolean;

    @Output('onClick')
    emitClick: EventEmitter<any> = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    get colorClass() {
        return {
            'blue': this.color === 'blue',
            'orange': this.color === 'orange'
        }
    }

    handleClick() {
        this.emitClick.emit();
    }
}
