import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './components/button/button.component';
import { ButtonHelpComponent } from './components/help/help.component';
import {PROJECT_SETTINGS} from '../config/project';
import {PopupStateComponent} from "./components/popup-state/popup-state.component";

@NgModule({
    declarations: [
        ButtonComponent,
        ButtonHelpComponent,
        PopupStateComponent],
    imports: [
        CommonModule
    ],
    exports: [
        ButtonComponent,
        ButtonHelpComponent,
        PopupStateComponent,
    ]
})
export class SharedModule { }
