import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';


const routes: Routes = [
  {path: '', loadChildren: () => import('./chat-quiz/chat-quiz.module').then(value => value.ChatQuizModule) },
  // {path: 'quiz', loadChildren: () => import('./quiz/quiz.module').then(m => m.QuizModule)},
  {path: '**', redirectTo: 'login', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
